<template>
  <table style="width: 100%; height: 100%">
    <tr>
      <td style="text-align: center; vertical-align: middle;">
        <div class="login-container">
          <el-form ref="form" :model="form" :rules="rules" class="login-form" auto-complete="on" label-position="left">
            <div class="title-container">
              <h3 class="title">
                {{ $t('forgotPassword.title') }}
              </h3>
              <lang-select v-if="multilingual" class="set-language" />
            </div>

            <el-collapse-transition>
              <div v-if="!formDone" class="content-container">
                <div class="description">
                  <span>{{ $t('forgotPassword.description') }}</span>
                </div>
                <el-form-item prop="username">
                  <span class="svg-container">
                    <svg-icon icon-class="user" />
                  </span>
                  <el-input
                    v-model="form.username"
                    :placeholder="$t('login.code')"
                    name="username"
                    type="text"
                    auto-complete="on"
                  />
                </el-form-item>
                <el-form-item prop="email">
                  <span class="svg-container">
                    <svg-icon icon-class="email" />
                  </span>
                  <el-input
                    v-model="form.email"
                    :placeholder="$t('common.email')"
                    name="email"
                    type="text"
                    auto-complete="on"
                  />
                </el-form-item>
                <el-button
                  :loading="loading"
                  type="primary"
                  style="width:100%;margin-bottom:30px;"
                  @click.native.prevent="handleSubmit"
                >
                  {{ $t('forgotPassword.submit') }}
                </el-button>
                <el-collapse-transition>
                  <el-alert v-if="errorMsg" :title="errorMsg" type="error" :closable="false" />
                </el-collapse-transition>
              </div>
            </el-collapse-transition>

            <el-collapse-transition>
              <div v-if="formDone" class="message-container">
                <span>{{ $t('forgotPassword.done', { email: form.email }) }}</span>
              </div>
            </el-collapse-transition>
            <div class="footer">
              <router-link :to="{ name: 'login' }">
                <span>{{ $t('login.logIn') }}</span>
              </router-link>
            </div>
          </el-form>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import LangSelect from '@/components/LangSelect';
import { focusFirstInputInEl } from '@/utils/';
import { validatorRequire, validatorEmail } from '@/utils/validators';
export default {
  components: {
    SvgIcon,
    LangSelect
  },
  data() {
    return {
      loading: false,
      errorMsg: '',
      form: {},
      rules: {
        email: [
          {
            validator: validatorRequire
          },
          {
            validator: validatorEmail
          }
        ],
        username: [
          {
            validator: validatorRequire
          }
        ]
      },
      formDone: false
    };
  },
  computed: {
    multilingual() {
      return this.$store.getters['multilingual'];
    }
  },
  mounted() {
    focusFirstInputInEl(this.$el);
  },
  methods: {
    async handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true;
          this.errorMsg = '';
          try {
            await this.$store.dispatch('forgotPassword', {
              username: this.form.username,
              email: this.form.email
            });
            this.formDone = true;
          } catch (err) {
            this.errorMsg = err.message || this.$i18n.t('common.error');
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
</script>

<style src="@/styles/components/login/input.scss" lang="scss"></style>
<style rel="stylesheet/scss" src="@/styles/components/login/index.scss" lang="scss" scoped></style>
